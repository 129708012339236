export function isProjectIssuesDataExist (chartData) {
    for (const data of chartData) {
        if (data.total_issues > 0) {
            return true;
        }
    }
    return false;
};
export function isCurrentCheckInsDataExist (chartData) {
    for (const data of chartData) {
        if (data.active_users.length > 0) {
            return true;
        }
    }
    return false;
};
export function isProjectDataExist (chartData) {
    for (const data of chartData) {
        if (data.delayed_days > 0) {
            return true;
        }
    }
    return false;
};
